import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const HeroTestimonialWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 0;

  .headshot {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 40px;
    overflow: hidden;
    position: relative;

    ${atMinWidth.sm`
      width: 80px;
      height: 80px;
    `}

    .gatsby-image-wrapper {
      display: block;
      position: absolute;
      inset: 0;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .text {
    ${font('text', 'xs', '400')}
    color: ${colors.gray[600]};

    ${atMinWidth.sm`
      ${font('text', 'sm', '400')}
    `}

    .body {
      p {
        margin-bottom: 0.625em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .attribution {
      margin-top: 0.625em;
    }
  }
`;
